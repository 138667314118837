import React from "react";
import { Container } from "react-bootstrap";

function News7() {
  return (
    <Container>
      <br />
      <p style={{ color: "grey", fontSize: "1.5rem", margin: "2rem" }}>
        November 20, 2024
      </p>
      <h2
        style={{ color: "#003561", marginBottom: "1rem", marginLeft: "2rem" }}
      >
        Stratus Materials to AABC, Las Vegas, Dec 9-12, 2024{" "}
      </h2>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Mark your calendars as Stratus Materials heads to the{" "}
        <a
          href="https://www.advancedautobat.com/us"
          target="_blank"
          rel="noopener noreferrer"
        >
          Advanced Automotive Battery Conference, Las Vegas
        </a>{" "}
        on Dec 9 – 12, 2024. On December 10th, Stratus’ CEO, Jay Whitacre, is
        leading a talk titled <b>‘Stabilized Lithium-Rich Cathode Materials;
        Next-Generation Products with High Capacity and Cycle Stability’</b>. Don’t
        miss this year’s automotive battery conference to discover the technical
        issues and opportunities impacting the pace and path of vehicle
        electrification worldwide. For more information or to schedule an
        appointment with Stratus Materials during the event, please reach out at{" "}
        <a href="mailto:info@stratusmaterials.com">
            info@stratusmaterials.com
          </a>
        .
      </p>

      <a
        style={{
          fontSize: "1.5rem",
          margin: "2rem",
          textDecoration: "None",
          display: "flex",
          justifyContent: "center",
        }}
        href="/news"
      >
        <strong style={{ textAlign: "center" }}>BACK TO NEWS</strong>
      </a>
      <br />
      <br />
    </Container>
  );
}

export default News7;
